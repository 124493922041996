/* eslint-disable */

import axios from "axios";

const instance = axios.create({
  baseURL: "https://leadcenter.pt/lead_center_v2/api/public",
  headers: {
    "content-type": "application/json",
    Authorization: "Basic YXBpX3ljX3VzZXI6ZTZLLzc1SjMpcFl+c153TA==",
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});
// const instanceStaging = axios.create({
//   baseURL: "https://leadcenter.pt/lead_center_v2/api_staging/public",
//   headers: {
//     "content-type": "application/json",
//     "Authorization": "Basic YXBpX3ljX3VzZXI6ZTZLLzc1SjMpcFl+c153TA==",
//   },
//   xsrfCookieName: "XSRF-TOKEN",
//   xsrfHeaderName: "X-XSRF-TOKEN",
// });

export default {
  // SIGN IN
  async login(data) {
    try {
      const res = await instance.post("/dashboard/login", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  async listarLeads(data) {
    try {
      const res = await instance.post("/dashboard/listar/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarLeadsRGPD(data) {
    try {
      const res = await instance.post("/rgpd/listar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarLeadsIncumprimento(data) {
    try {
      const res = await instance.post(
        "/dashboard/listar/leads/incumprimento",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarConsultores(data) {
    try {
      const res = await instance.post(
        "/dashboard/consultor/listar/todos",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async atualizarProdutoConsultor(data) {
    try {
      const res = await instance.post(
        "/dashboard/consultor/produto/atualizar",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async apagarConsultor(data) {
    try {
      const res = await instance.post("/dashboard/consultor/apagar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async adicionarConsultor(data) {
    try {
      const res = await instance.post("/dashboard/consultor/adicionar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async atualizarFeriasConsultor(data) {
    try {
      const res = await instance.post(
        "/dashboard/consultor/ferias/atualizar",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarTaxas(data) {
    try {
      const res = await instance.post("/dashboard/taxa/listar/todas", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async atualizarTaxas(data) {
    try {
      const res = await instance.post("/dashboard/taxa/atualizar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async converterLead(data) {
    try {
      const res = await instance.post("/dashboard/converter/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};
